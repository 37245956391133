@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "components/animate";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";

//@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,300,100);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);

body {
  font-family: 'Lato', sans-serif;
}

@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon.eot?ij7b0u');
    src:    url('../fonts/icomoon.eot?ij7b0u#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?ij7b0u') format('truetype'),
        url('../fonts/icomoon.woff?ij7b0u') format('woff'),
        url('../fonts/icomoon.svg?ij7b0u#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-construction:before {
    content: "\e900";
}
.icon-consultation:before {
    content: "\e901";
}
.icon-demolition:before {
    content: "\e902";
}
.icon-design:before {
    content: "\e903";
}
.icon-search:before {
    content: "\e904";
}
.icon-facebook:before {
    content: "\e905";
}
.icon-instagram:before {
    content: "\e906";
}
.icon-twitter:before {
    content: "\e907";
}
.icon-linkedin2:before {
    content: "\e908";
}


.parallax {
  //background-attachment: fixed;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: cover;
  //background-size: auto 100%;
  //height: 400px;
  position: relative;
  //height: 400px;
}

a {
  color: #00aeef;
  &:focus,
  &:hover {
    color: #00548e;
    text-decoration: none;
  }
}

footer a {
  color: #fff;
  margin-right: 10px;
  &:focus,
  &:hover {
    color: #f2f2f2;
    text-decoration: none;
  }
}

h2 {
  font-size: 18px;
  color: #00aeef;
  @media (min-width: 992px){ 
    font-size: 20px;
  }
  @media (min-width: 1200px){ 
    font-size: 30px;
  }
}

.contacts {
  font-size: 12px;
  color: #fff;
  padding-bottom: 20px;
  @media (min-width: 992px){ 
    font-size: 14px;
  }
}

#mobile {
  text-align: right;
  color: #000;
  font-size: 15px;
  padding-top: 10px;
  margin-right: 0px;
  padding-bottom: 10px;
  line-height: 20px;
  @include transition(all 0.2s ease);
  @media (min-width: 768px){ 
    font-size: 20px;
    margin-right: 10px;
  }
}

.navbar-toggle {
  margin-bottom: 10px;
  border-radius: 0px;
  margin-top: 6px;
}

.navbar-default {
  padding: 10px 0;
}

.navbar-default .navbar-toggle {
  border-color: #939598;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #939598;
  border-radius: 0px;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: #333;
}

.btn-default, .gform_button {
  background-color: #00aeef;
  border-radius: 0px;
  border-color: #00aeef;
  color: #fff;
  &:focus,
  &:hover {
    background-color: #00548e;
  border-color: #00548e;
    color: #fff;
  }
}

.btn-inverse {
  background-color: transparent;
  border-color: #fff;
  &:focus,
  &:hover {
    background-color: #000;
  border-color: #fff;
  }
}

.navbar-default {
  background-color: rgba(255, 255, 255, 0.9);
  border-color: transparent;
}

.navbar-nav {
  @media (min-width: 768px){ 
    float: right;
  }
}

.navbar-nav>li>a {
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 30px;
  text-transform: uppercase;
  color: #939598;
}

.navbar-default .navbar-nav>li>a, .navbar-default .navbar-text {
    color: #222;
    letter-spacing: .02em;
    font-weight: 700;
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
  color: #00aeef;
  background-color: transparent;
}

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
  color: #00aeef;
}

.navbar-brand {
  background-image: url("../images/bricol-logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 181px;
  height: 70px;
  text-indent: -9999px;
  margin-bottom: 10px;
  margin-top: -30px;
  padding: 0px;
  margin-left: 15px;
  @include transition(all 0.2s ease);

}

.navbar-shrink {
  #mobile {
    opacity: 0;
    margin-top: -40px;
  }
  .navbar-brand {
    background-image: url(../images/bricol-logo.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 138px;
    height: 50px;
    text-indent: -9999px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    //margin-left: 0px !important;
    //filter: saturate(0%);
  }
  .navbar-toggle {
    margin-bottom: 0px;
    border-radius: 0;
    margin-top: 9px !important;
    @include transition(all 0.2s ease);
  }
}

#hero {
  position: relative;
  padding: 190px 0 50px;
  overflow: hidden;

  #hero-burb {
    width: 50%;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 15px;
    h2 {
      color: #fff;
          font-weight: 100;
    font-size: 40px;
    }
    p {
      color: #fff;
      font-size: 20px;
      font-weight: 100;
      margin-bottom: 40px;
    }
    
    @media (max-width: 767px){ 
      margin-top: 150px;
      width: 100%;
      h2 {
        font-size: 25px;
      }
      p {
        font-size: 14px;
      }
    }  
  }
}

#blurbs {
  text-align: center;
  font-size: 14px;
  span {
    font-size: 60px;
    display: block;
  }
}
.grey-opacity {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 50px;
    color: #fff;
}

.gallery-opacity {
  height: 200px;
      text-align: center;
    padding-top: 85px;
}

#page-hero {
  min-height: 30vh;
  text-align: center;
  position: relative;
}

#logo {
  top: 25%;
  width: 100%;
  text-align: center;
  position: absolute;
  .img-responsive {
    display: inline-block;
  }
  padding: 0 20px;
  h2 {
    color: #fff;
    margin: 50px 0 100px;
    font-size: 17px;
    @media (min-width: 768px){ 
      font-size: 30px;
    }
    font-weight: 100;
  }
}

#hero-bottom {
  background-image: url(../images/hero-bottom.png);
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  height: 105px;
  background-position: 50% 0;
  background-repeat: no-repeat;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 5;
  i {
    color: #00aeef;
  }
}

#scrollto {
  text-align: center;
}

.white {
  padding: 100px 0;
  font-size: 18px;
  background-color: #fff;
}

.grey {
  background-color: transparent;
  .container {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

#services {
  padding: 0px;
  @media (min-width: 768px){ 
    padding: 100px 0;
  }
  text-align: center;
    .img-responsive {
      display: inline-block;
    }
}

.services #services p {
  margin-top: 20px;
}

.quote, .quote-image {
  position: relative;
  height: auto;
  padding: 20px 0;
  min-height: 135px;
  @media (min-width: 768px){ 
    height: 400px;
    padding: 0px;
  }
}

.quote {
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  span {
    display: block;
    padding: 0px;
    text-transform: uppercase;
    font-size: 25px;
    line-height: 1.2em;
    @media (min-width: 768px){ 
      padding: 70px 15px;
    }
  }
}

.quote-image {
  background-image: url(../images/kitchen.jpg);
  background-position: center center;
  background-size: cover;
}

.gallery-01,
.gallery-02,
.gallery-03,
.gallery-04,
.gallery-05,
.gallery-06 {
  height: 400px;
  background-position: center center;
  background-size: cover;
  margin-bottom: 20px;
}

.gallery-01 {
  background-image: url(../images/gallery-01.jpg);
}

.gallery-02 {
  background-image: url(../images/gallery-02.jpg);
}

.gallery-03 {
  background-image: url(../images/gallery-03.jpg);
}

.gallery-04 {
  background-image: url(../images/gallery-04.jpg);
}

.gallery-05 {
  background-image: url(../images/gallery-02.jpg);
  height: 200px;
}

.gallery-06 {
  background-image: url(../images/gallery-01.jpg);
  height: 200px;
}

.nav>li>a {
  padding: 10px 9px;
  font-size: 13px;
}


footer {
  background: #222;
  padding: 10px 0;
  color: #fff;
  padding: 50px 0 100px 0;
  small {
    color: #ccc;
    font-size: 10px;
  }
  .btn-inverse {
    margin-left: 0px;
  }
  @media (max-width: 767px){ 
    text-align: center !important;
    .text-right {
      text-align: center !important;
    }
  }
  .nav>li {
    border-bottom: 1px solid #666;
    
    
    >a {
      margin-left: 0px;
      padding-left: 0px;
      &:hover {
        color: #00aeef;
        background: transparent;
      }
    }
  }
}

.footer-testimonial {
  background-color: #000;
  padding: 10px;
  font-size: 12px;
  color: #fff;
  margin-bottom: 15px;
}

@keyframes pulse_animation {
	0% { transform: scale(1); }
	30% { transform: scale(1); }
	40% { transform: scale(1.2); }
	50% { transform: scale(1); }
	60% { transform: scale(1); }
	70% { transform: scale(1.2); }
	80% { transform: scale(1); }
	100% { transform: scale(1); }
}

.fa-caret-down {
	animation-name: pulse_animation;
	animation-duration: 5000ms;
	transform-origin:70% 70%;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.gfield_label {
  color: #fff;
}

#map {
  border: 5px solid #000;
}

.gform_wrapper form {
  margin-bottom: 20px !important;
}

//
// Gravity Forms
//


.gform_wrapper ul {
  padding-left: 0;
  list-style: none; }

.gform_wrapper li {
  margin-bottom: 15px; }

.gform_wrapper form {
  margin-bottom: 0; }

.gform_wrapper .gfield_required {
  padding-left: 1px;
  color: #b94a48; }

.ginput_container input, .ginput_container select, .ginput_container textarea {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #fff;
  vertical-align: middle;
  background-color: #111;
  border: 1px solid #111;
  border-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }
  
  
.ginput_container input:-moz-placeholder, .ginput_container select:-moz-placeholder, .ginput_container textarea:-moz-placeholder {
  color: #fff; }

.ginput_container input::-moz-placeholder, .ginput_container select::-moz-placeholder, .ginput_container textarea::-moz-placeholder {
  color: #fff; }

.ginput_container input:-ms-input-placeholder, .ginput_container select:-ms-input-placeholder, .ginput_container textarea:-ms-input-placeholder {
  color: #fff; }

.ginput_container input::-webkit-input-placeholder, .ginput_container select::-webkit-input-placeholder, .ginput_container textarea::-webkit-input-placeholder {
  color: #fff; }

.ginput_container input:focus, .ginput_container select:focus, .ginput_container textarea:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6); }

.ginput_container input[disabled], .ginput_container select[disabled], .ginput_container textarea[disabled], .ginput_container input[readonly], .ginput_container select[readonly], .ginput_container textarea[readonly], fieldset[disabled] .ginput_container input, fieldset[disabled] .ginput_container select, fieldset[disabled] .ginput_container textarea {
  cursor: not-allowed;
  background-color: #eee; }

textarea.ginput_container input, textarea.ginput_container select, textarea.ginput_container textarea {
  height: auto; }

.ginput_container textarea {
  height: auto; }

.gform_button {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.428571429;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd; }

.gform_button:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.gform_button:hover, .gform_button:focus {
  color: #333;
  text-decoration: none; }

.gform_button:active, .gform_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.gform_button.disabled, .gform_button[disabled], fieldset[disabled] .gform_button {
  cursor: not-allowed;
  pointer-events: none;
  opacity: .65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.gform_button:hover, .gform_button:focus, .gform_button:active, .gform_button.active, .open .dropdown-toggle.gform_button {
  color: #fff;
  background-color: #3276b1;
  border-color: #285e8e; }

.gform_button:active, .gform_button.active, .open .dropdown-toggle.gform_button {
  background-image: none; }

.gform_button.disabled, .gform_button[disabled], fieldset[disabled] .gform_button, .gform_button.disabled:hover, .gform_button[disabled]:hover, fieldset[disabled] .gform_button:hover, .gform_button.disabled:focus, .gform_button[disabled]:focus, fieldset[disabled] .gform_button:focus, .gform_button.disabled:active, .gform_button[disabled]:active, fieldset[disabled] .gform_button:active, .gform_button.disabled.active, .gform_button[disabled].active, fieldset[disabled] .gform_button.active {
  background-color: #428bca;
  border-color: #357ebd; }

.gform_wrapper .gfield_error .gfield_label {
  color: #b94a48; }

.gform_wrapper .gfield_error input, .gform_wrapper .gfield_error select, .gform_wrapper .gfield_error textarea {
  border-color: #eed3d7;
  background-color: #f2dede;
  color: #b94a48; }

.gform_wrapper .gfield_error input:focus, .gform_wrapper .gfield_error select:focus, .gform_wrapper .gfield_error textarea:focus {
  border-color: #b94a48;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(185, 74, 72, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(185, 74, 72, 0.6); }

.validation_error {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48; }

.validation_error h4 {
  margin-top: 0;
  color: inherit; }

.validation_error .alert-link {
  font-weight: bold; }

.validation_error>p, .validation_error>ul {
  margin-bottom: 0; }

.validation_error>p+p {
  margin-top: 5px; }

.validation_error hr {
  border-top-color: #e6c1c7; }

.validation_error .alert-link {
  color: #953b39; }

#gforms_confirmation_message {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px; }

#gforms_confirmation_message h4 {
  margin-top: 0;
  color: inherit; }

#gforms_confirmation_message .alert-link {
  font-weight: bold; }

#gforms_confirmation_message>p, #gforms_confirmation_message>ul {
  margin-bottom: 0; }

#gforms_confirmation_message>p+p {
  margin-top: 5px; }

.gallery-row {
  padding: 15px 0; }

.aligncenter {
  display: block;
  margin: 0 auto; }

.alignleft {
  float: left; }

.alignright {
  float: right; }

figure.alignnone {
  margin-left: 0;
  margin-right: 0; }
  
  
  .navbar-default .navbar-toggle {
    border-color: #00aeef;
    margin-top: 2px;
}
  .navbar-default .navbar-toggle:hover {
    background-color: #000;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #00aeef;
    border-radius: 0;
}

.gallery-06, section {overflow: hidden;}

// Bricol Design

.bricol-design {
  .navbar-fixed-top {
    background-color: rgba(0, 0, 0, 0.9);
    #mobile {
      color: #fff;
    }
  }
  .navbar-default .navbar-nav>li>a, .navbar-default .navbar-text {
    color: #fff;
  }
  
  .navbar-brand {
    background-image: url("../images/bricol-design-logo-reversed.svg");
    width: 172px;
    height: 70px;
  }
  
  .navbar-shrink {
    .navbar-brand {
      width: 123px;
      height: 50px;
    }
  }
  
  main>.white {
    background-color: #111;
    color: #f2f2f2;
  }
  
  .photoswipe_gallery figure {
    width: 140px;
  }
  
}

.dropdown-menu>.active>a, .dropdown-menu>.active>a:focus, .dropdown-menu>.active>a:hover {
    background-color: #00aeef;
}

#contact {
  position: relative;
}



